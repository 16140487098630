<template>
  <div class="login-view">
    <div class="bg">
      <img src="@/assets/img/login-bg.png" alt="" class="bg-img" />
    </div>
    <div class="login-bd">
      <div class="container">
        <div class="title">
          <img src="@/assets/img/bowtz_logo.png" alt="" class="logo" />
        </div>
        <div class="login-form">
          <div class="form-bd">
            <div class="login-form-title">{{$t('login.User login')}}</div>
            <div class="login-form-desc">{{$t('login.Welcome')}}</div>
            <div>
              <el-form :model="formData" ref="loginForm"  label-position="top" style="max-width: 360px" :rules="rules">
                <el-form-item :label="$t('login.Account')" prop="account">
                  <el-input v-model="formData.account" :placeholder="$t('login.Account')" clearable :disabled="isLock">
                    <template #prefix>
                      <img class="email-icon" src="@/assets/img/email.png" />
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item  :label="$t('login.Password')" prop="password">
                  <el-input
                    v-model="formData.password"
                    type="password"
                    :placeholder="$t('login.Password')"
                    show-password
                    :disabled="isLock"
                  >
                    <template #prefix>
                      <img class="email-icon" src="@/assets/img/passwd.png" />
                    </template>
                  </el-input>
                  <div class="login-tips">
                    <el-popover
                      :width="300"
                      popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
                    >
                    <template #reference>
                      <div><i class="el-icon-warning"></i></div>
                    </template>
                    <template #default>
                      <div>{{$t('profile.FormError1')}}</div>
                    </template>
                    </el-popover>
                  </div>
                </el-form-item>
                <el-form-item label="" >
                  <el-switch v-model="formData.remember" :disabled="isLock" />
                  <span class="remember-me">{{$t('login.Remember Me')}}</span>
                  <div class="forgot-passwd">
                    <!-- <span>Forgot Password?</span> -->
                    <el-dropdown ref="dropdown1" trigger="click" placement="top">
                      <span class="el-dropdown-link"> {{$t('login.Lost Password')}} </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item>{{$t('login.ContactAdmin')}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div> 
                </el-form-item>
                <el-form-item>
                  <div v-if="countDown !== null && countDown >=0 " class="count-down">
                    <!-- hours,minutes,seconds-->
                    <span>{{countDownData.hours < 10 ? '0'+countDownData.hours :countDownData.hours}}:</span>
                    <span>{{countDownData.minutes < 10 ? '0'+countDownData.minutes: countDownData.hours}}:</span>
                    <span>{{countDownData.seconds}}</span>
                  </div>
                  <el-button type="success" @click="onSubmit" :disabled="isLock">{{$t('login.Login')}}</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="login-ft">
          {{$t('login.Description')}}
        </div>
      </div>
    </div>

    <!-- 强制修改密码 -->
    <forceUpdatePassword ref="forceUpdatePassword"></forceUpdatePassword>
  </div>
</template>
<script>
  import { login } from "@/libs/api/user";
  import { checkIsEmail, checkIsPasswordRegex } from "@/utils/common.js";
  import forceUpdatePassword from './force-update-password'

  import md5 from 'js-md5'
  import store from '@/store'
  export default {
    name:'',
    components: {
      forceUpdatePassword
    },
    props: {
      location: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        formData:{
          account:'',
          password:'',
          remember:false
        },
        rules:{
          account: [{ validator: this.checkEmail, trigger: 'blur' }],
          password: [{ validator: this.checkPassword, trigger: 'blur' }]
        },
        isLock:false, // 三次登录密码错误后，会锁定，为true
        countDown:null,
        countDownData:null
      }
    },
    created(){
      console.log('------登录页面---created')
    },
    mounted(){
      console.log('------登录页面---mounted')
      let account = localStorage.getItem("account");
      if(account){
        this.formData.account = account
        this.formData.remember = true
      }
    },
    methods: {
      // 获取用户系统
      getBrowserNameVersion(){
        let str = navigator.userAgent;
        return str
      },
      // 校验邮箱
      checkEmail(rule,value,callback){
        console.log('邮箱value,rule',value,rule,callback)
        if (!value) {
          return callback(new Error(this.$t('formValidate.required fields')))
        } else {
          let isEmail = checkIsEmail(value)
          if(!isEmail){
            return callback(new Error('Please enter the correct email address'))
          }else {
            callback()
          }
        }
      },
      // 检验密码
      checkPassword(rule,value,callback){
        console.log('密码value,rule',value,rule,callback)
        if (!value) {
          return callback(new Error(this.$t('formValidate.required fields')))
        } else {
          let res = checkIsPasswordRegex(value)
          console.log('校验密码规则---',res)
          if(res){
            callback()
          } else {
            return callback(new Error('密码格式有误！'))
          }
        }
      },
      // 登录点击
      onSubmit(){
        this.$refs.loginForm.validate( valid => {
          if (valid) {
            console.log('表单校验通过',)
            this.login()
          } else {
            // loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 登录接口api
      async login(){
        let params = {
          account: this.formData.account,
          password: md5(this.formData.password),//md5加密
          loginOs: this.getBrowserNameVersion()
        }
        try {
          let res = await login(params)
          let resData = res.data
          console.log('登录接口返回结果',res)
          if(resData.state){
            let data = resData.data
            let userData ={
              ...data,
              role:data.userRoleName,
              username: data.userName,
              avatar: data.headImg,
              ability:[{"action":"manage","subject":"all"}],
              extras:{"eCommerceCartItemsCount":5}
            }

            if(this.formData.remember){
              localStorage.setItem("account",data.loginId);
            } else {
              localStorage.removeItem('account')
            }

            sessionStorage.setItem("storeName",data.storeName);
            sessionStorage.setItem("storeId",data.storeId);
            sessionStorage.setItem("userType",this.switchRoleType(data.userType));
            
            localStorage.setItem("storeId",data.storeId);
            localStorage.setItem("storeName",data.storeName);
            localStorage.setItem('li18', data.language.languageCode)
            localStorage.setItem('language', this.switchLanguageCode(data.language.languageCode))
            localStorage.setItem('userData', JSON.stringify(userData))
            localStorage.setItem("merchantId",data.merchantId)
            localStorage.setItem('localLanguage', JSON.stringify(data.language))
            
            localStorage.setItem('accessToken',data.token)
            localStorage.setItem('refreshToken',data.token)

            // forceUpdatePassword true 需要重置密码
            if(resData.code === 1){
              // this.$router.replace("/dashboard")
              if(resData.data.forceUpdatePassword){
                // 强制更新密码
                this.$refs.forceUpdatePassword.open()
              } else {
                window.location.href = location.origin;
              }
            } 
          } else {
            if(resData.code === 127){
              console.log('---------resData',resData.code)
              // 三次密码登录错误
              this.isLock = true
              this.countDown = resData.data.countDown
              this.handleCountDown()
            }
            this.$message.error(resData.message);
          }
        } catch (err) {
          const { message} = err;
          this.$message.error(message)
        }
      },
      // 处理多语言code
      switchLanguageCode(code){
        switch (code) {
          case 'en_US':
            code ="en"
            break
          case 'zh_CN':
            code ="zh"
            break
        }
        return code
      },
      // 登录的角色
      switchRoleType(type){
        console.log('用户角色',type)
        // // 201 经销商用户； 202 运维用户； 203 总部用户； 204 门店用户
        let str ='经销商用户；'
        switch (type) {
          case 201:
            str ="经销商用户；"
            break
          case 202:
            str ="运维用户"
            break
          case 203:
            str ="总部用户"
            break
          case 204:
            str ="门店用户"
            break
        }
        return str
      },
      // 倒计时
      handleCountDown(){
        let countDown = this.countDown
        let timer = setInterval(() => {
          if(countDown < 0){
            this.isLock = false
            clearInterval(timer)
          }
          console.log({countDown})
          this.countDown = countDown--
          this.getCountData()
        },1000)

      },
      // 处理显示
      getCountData () {
        let distance = this.countDown
        const _second = 1
        const _minute = _second * 60
        const _hour = _minute * 60
        // const _day = _hour * 24
        const time = {
          hours: Math.floor(distance  / _hour),
          minutes: Math.floor(distance % _hour / _minute),
          seconds: Math.floor(distance % _minute / _second)
        }
        this.countDownData= time
      }
    }
  }
</script>
<style lang="scss" scoped>
  .login-view{
    width: 100vw;
    height: 100vh;
    .bg{
      width: 100%;
      height: 100%;
      background: #000;
      // background: #000 url('../../assets/login/background.png') no-repeat 200% 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .bg-img{
        display: inline-block;
        // width: auto;
        // height: 100%;
        // margin-left: -30%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .login-bd{
      width: 550px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      .container{
        .title{
          .logo{
            display: block;
            width: 140px;
            height: auto;
            margin: 0 auto;
            padding-bottom: 30px;
          }
        }
        .login-form{
          width: 100%;
          height: 400px;
          background: rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          color: #fff;
          display: flex;
          align-items: center;
          .form-bd{
            width: 60%;
            margin: 0 auto;
            .login-form-title{
              font-size: 24px;
              // padding-top: 30px;
              color: rgba(255,255,255,0.8);
            }
            .login-form-desc{
              font-size:12px;
              color: rgba(255,255,255,0.8);
              margin: 6px 0 10px 0;
            }
            .email-icon{
              display: block;
              width: 14px;
            }
            .login-tips{
              position: absolute;
              right: -40px;
              top: 0;
              cursor: pointer;
              .el-icon-warning{
                padding: 12px;
                font-size: 16px;
              }
            }
            .remember-me{
              font-size: 12px;
              margin-left: 10px;
            }
            .forgot-passwd{
              position: absolute;
              right: 0;
              top: 50%;
              font-weight: 400;
              cursor: pointer;
              line-height: 0;
              .el-dropdown{
                color: #22C55E;
                text-decoration: underline;
                font-size: 12px;
              }
            }
            .count-down{
              color: #FF0000;
              height: 30px;
              line-height: 30px;
              text-align: center;
            }

            // 重置样式
            ::v-deep .el-switch.is-checked .el-switch__core{
              border-color: #35B871;
              background-color: #35B871;
            }
            .el-form-item__content{
              position: relative;
            }
            ::v-deep .el-form-item{
              margin-bottom: 10px;
            }
            ::v-deep .el-form-item:last-child{
              margin-bottom: 0;
            }
            ::v-deep .el-form--label-top .el-form-item__label {
              padding-bottom: 0;
              line-height: 30px;
            }
            .el-button--success{
              width: 100%;
              border: none;
            }
            ::v-deep .el-form-item__error{
              margin-left: 50%;
              transform: translateX(-50%);
            }
            ::v-deep .el-input__prefix{
              left: 11px;
              display: flex;
              align-items: center;
            }
          }
        }
        .login-ft{
          margin-top: 50px;
          text-align: center;
          color: #999;
        }
      }
      
    }
  }
</style>