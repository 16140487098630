<template>
  <div class="force-update-password">
    <el-dialog 
      v-model="dialogVisible" 
      :title="$t('profile.Change Password')" 
      :before-close="close" 
      :visible.sync="dialogVisible"
      width="500px"
    >
    <div class="form-box">
      <el-form 
        label-width="100px"
        style="width: 100%"
        :model="formPassword"
        ref="passwordForm" 
        :rules="rules"
        size="small"
        label-position="top"
      >
        <el-form-item :label="$t('profile.Password')" prop="oldPassword">
          <el-input
            v-model="formPassword.oldPassword"
            type="password"
            :placeholder="$t('profile.Password')"
            show-password
          >
            <template #prefix>
              <span><i class="el-icon-lock"></i></span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('profile.New Password')" prop="newPassword">
          <el-input
            v-model="formPassword.newPassword"
            type="password"
            :placeholder="$t('profile.New Password')"
            show-password
          >
            <template #prefix>
              <span><i class="el-icon-lock"></i></span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('profile.Confirm New Password')" prop="confirmNewPassword">
          <el-input
            v-model="formPassword.confirmNewPassword"
            type="password"
            :placeholder="$t('profile.Confirm New Password')"
            show-password
          >
            <template #prefix>
              <span><i class="el-icon-lock"></i></span>
            </template>
          </el-input>
        </el-form-item>

        <!-- 保存按钮 -->
        <div class="form-btn-section">
          <el-button size="small" color="#28C76F" @click="close">{{$t('button.Cancel')}}</el-button>
          <el-button size="small"  color="#28C76F" type="success" @click="submitFormPasswd">{{$t('button.Save')}}</el-button>
        </div>

        <!-- <el-form-item>
          <el-button 
            color="#28C76F" 
            type="success"
            @click="submitFormPasswd"
          >{{$t('button.Save')}}</el-button>
        </el-form-item> -->
      </el-form>
    </div>
  </el-dialog>
  </div>
</template>
<script>
  import { updatePassword } from "@/libs/api/user";
  import { checkIsPasswordRegex } from "@/utils/common";
  import md5 from 'js-md5'
  let loading;
  export default {
    name:'',
    components: {
    },
    props: {
      location: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        dialogVisible:false,
        formPassword:{
          oldPassword:'',
          newPassword:'',
          confirmNewPassword:''
        },
        rules:{
          oldPassword: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkOld, trigger: 'blur' }
          ],
          newPassword: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkNew, trigger: 'blur' }
          ],
          confirmNewPassword: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { validator: this.checkConfirm, trigger: 'blur' }
          ]
        }
      }
    },
    created(){
      
    },
    mounted(){
      
    },
    methods: {
      open(){
        this.dialogVisible = true
      },
      // 关闭弹窗
      close(){
        this.$refs.passwordForm.resetFields()
        this.dialogVisible = false
        window.location.href = location.origin;
      },
      // 校验 ----旧密码
      checkOld(rule,value,callback){
        console.log('密码value',value,rule)
        if (!value) {
          return callback(new Error('Please input password'))
        } else {
          let res = checkIsPasswordRegex(value)
          if(res){
            callback() 
          } else {
            return callback(new Error(this.$t('login.FormError2')))
          }
        }
      },
      // 校验 --- 新密码
      checkNew(rule,value,callback){
        console.log('新密码密码',value,rule)
        if (!value) {
          return callback(new Error(this.$t('formValidate.required fields')))
        } else {
          let res = checkIsPasswordRegex(value)
          if(res){
            if (this.formPassword.confirmNewPassword !== '') {
              this.$refs.passwordForm.validateField('confirmNewPassword');
            }
            callback()
          } else {
            return callback(new Error(this.$t('login.FormError2')))
          }
        }
      },
      // 校验 --- 新密码确认
      checkConfirm(rule,value,callback){
        console.log('新密码--确认',value,rule)
        if (!value) {
          return callback(new Error(this.$t('formValidate.required fields')))
          // loading.close()
        } else {
          if(value === this.formPassword.newPassword){
            callback()
          } else {
            return callback(new Error(this.$t('login.FormError2')))
          }
        }
      },
      // 表单---重置密码校验
      submitFormPasswd(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.passwordForm.validate( valid => {
          if (valid) {
            this.updataApi()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 重置密码--请求api
      async updataApi(){
        let { oldPassword, newPassword} = this.formPassword
        let params = {
          oldPassword:md5(oldPassword),
          newPassword:md5(newPassword)
        }
        try {
          const res = await updatePassword(params)
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('profile.Message1'),
              type:'success',
            });
            loading.close()
            window.location.href = location.origin;
          } else{
            loading.close()
            this.$message.error(response.message)
          }
        } catch (err) {
          loading.close()
          const { message} = err;
          this.$message.error(message)
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .force-update-password{
    .form-box{
      width: 320px;
      margin: 0 auto;
    }
    ::v-deep .el-form--label-top .el-form-item__label{
      margin-bottom: 0;
    }
    .form-btn-section{
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep .el-button{
        width: 120px;
      }
    }
  }
</style>